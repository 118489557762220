<template>
        <main-template @slotLoaded="loadCompany">
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>Base</template>
            <template v-slot="slotProps">
            <fetch-siren-root ref="rootSiren" :linkName='linkName' :link="link">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                        <div class="flex w-full overflow-hidden">
                            <div class="p-2 h-full">
                                <Hr-navigation :links="slotProps.links"/>
                            </div>
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-white-100 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden">
                                    <loading v-if="!response || !company"></loading>
                                    <template v-else>
                                            <SingleClientDetails ref="clientDetails" :details="company" :hasTable="false" @setLoading="setLoading" @getDetails="getDetails" @saveClicked="saveClicked" activeClass="bg-v3-purple bg-opacity-20 dark:bg-v3-purple dark:bg-opacity-20"/>

                                    </template>
                                </div>
                            </div>
                        </div>


                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";
import HrNavigation from "@/v3/components/navigation/HrNavigation.vue";
import HrPeoplePagination from "@/v3/components/pagination/HrPeoplePagination.vue";
import HrCompanyDepartmentsPagination from "@/v3/components/pagination/HrCompanyDepartmentsPagination.vue";
import HrCompanyContractsPagination from "@/v3/components/pagination/HrCompanyContractsPagination.vue";
import HrCompanyProjectsPagination from "@/v3/components/pagination/HrCompanyProjectsPagination.vue";
import SingleClientDetails from "@/v3/pages/SingleClientDetails.vue";
import fileDownload from "js-file-download";

export default {
    components: {
        SingleClientDetails,
        HrCompanyProjectsPagination,
        HrCompanyContractsPagination,
        HrCompanyDepartmentsPagination,
        HrPeoplePagination,
        HrNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate,

    },
    data() {
        return {
            slotProps: null,
            company: null,
            departments: null,
            contracts: null,
            projects:null,
            departmentsOpen: true,
            contractsOpen: false,
            projectsOpen: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        },


    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        loadCompany(slotProps) {
            this.companyUrl = slotProps.links.filter(link => link.rels.includes('admin.client')).first().href;
            console.log(this.companyUrl);
            Siren.get(this.companyUrl).then(res => {
                this.company = res.body;
            }, this);
            return this.company;
        },
        toggleDepartmentsOpen() {
            this.departmentsOpen = !this.departmentsOpen;
        },
        toggleContractsOpen() {
            this.contractsOpen = !this.contractsOpen;
        },
        toggleProjectsOpen() {
            this.projectsOpen = !this.projectsOpen;
        },
        getDarkLogo() {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_dark"
        },
        getLightLogo() {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=base_light"
        },
        setLoading() {
            this.$refs.clientDetails.editing = false;
            this.$refs.clientDetails.tabOpen = 'basics';
            this.details = null;
            this.loadingDetails = true;
        },

        saveClicked() {
            if (!this.submitting) {
                this.$setLaravelValidationErrorsFromResponse('');
                this.submitting = true;
                this.$refs.clientDetails.updateAction.perform(this.$refs.clientDetails.updateActionData).then(res => {
                    if (res.type === 'text/csv') {
                        fileDownload(res.text, 'export.csv');
                        this.submitting = false;
                        return;
                    }
                    this.$refs.clientDetails.editing = false;
                    this.submitting = false;
                    this.$store.dispatch(
                        "setMessage",
                        this.messages(res).entity.properties.get("success")
                    );
                    this.company = res.body;
                    this.$emit('success', res);
                }).catch(error => {
                    this.submitting = false;
                    if (error.status === 422) {
                        this.$setLaravelValidationErrorsFromResponse(
                            JSON.parse(error.response.text)
                        );
                    } else {
                        alert('There was an error processing your request.' + error);
                    }
                    this.$emit('failure');
                });
            }
        },
        messages(response) {
            return response.body.entities
                .filter(function (entity) {
                    return entity.rels.contains("status-messages");
                })
                .first();
        },
        getDetails(res)
        {
            this.$router.go(0);
        }
    }
}
</script>

